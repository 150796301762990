.site-layout .site-layout-background {
    background: #fff;
}
[data-theme="dark"] .site-layout .site-layout-background {
    background: #141414;
}

.card-padding .ant-card-body{
    padding: 0!important;
}

.card-half-padding .ant-card-body{
  padding: 6px!important;
}

.slider-menu {
  overflow: auto !important;
  height: 100vh !important;
  position: fixed !important;
  left: 0!important;
  width: 250px!important;
  z-index: 1000!important;
}

.layout-on-slider-collapse {
  width: calc(100% - 0) !important;
  transition: all 0.01s 0.11s!important;
}

.layout-on-slider-expand {
  width: calc(100% - 250px) !important;
  margin-left: 250px !important;
  transition: all 0.2s!important;
}

.ant-layout-content{
  padding: 10px!important;
}

.title{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list_padding .ant-list-item{
  padding: 0!important;
}

.type_bottom{
  margin-top: 2%;
  margin-bottom: 0!important;
  font-weight: bold;
}

.customer_note_bulk{
  margin-top: 1%;
  background-color: #fff2ce;
  padding: 1%;
  border-radius: 8px;
}

.note_bulk{
  margin-top: 1%;
  background-color: #fff2ce;
  padding: 1.5%;
  border-radius: 8px;
}

.product_attr_bulk:not(:empty) ~ .product_attr_bulk:not(:empty):before {
  content: ", ";
}

.editModeCategory {
  display: grid !important;
}

